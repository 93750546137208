const staticMenuItems = [
    {
        id: 68,
        name: {
            en: 'New In',
            ru: 'Новый В',
            tr: 'En Yeniler',
        },
        slug: {
            en: 'new-in',
            ru: 'novyi-v',
            tr: 'en-yeniler',
        },
        productCount: '',
    },
    {
        id: 396,
        name: {
            en: 'All',
            ru: 'All',
            tr: 'Tüm Ürünler',
        },
        slug: {
            en: 'all',
            ru: 'all',
            tr: 'tum-urunler',
        },
        productCount: '',
    },
    {
        id: 466,
        name: {
            en: 'Swimwear',
            ru: 'Плавки',
            tr: 'Bikini',
        },
        slug: {
            en: 'swimwear',
            ru: 'swimwear',
            tr: 'bikini',
        },
        productCount: '',
    },
    {
        id: 465,
        name: {
            en: 'City Chic',
            ru: 'City Chic',
            tr: 'City Chic',
        },
        child: [],
        slug: {
            en: 'city-chic',
            ru: 'city-chic',
            tr: 'city-chic',
        },
        productCount: '',
    },
    {
        id: 77,
        name: {
            en: 'Dress',
            ru: 'Платье',
            tr: 'Elbise',
        },
        slug: {
            en: 'dress',
            ru: 'plate',
            tr: 'elbise',
        },
        productCount: '',
    },

    {
        id: 375,
        name: {
            en: 'Plus Size',
            ru: 'Büyük Beden',
            tr: 'Büyük Beden',
        },
        slug: {
            en: 'plus-size',
            ru: 'buyuk-beden',
            tr: 'buyuk-beden',
        },
        productCount: '',
    },

    {
        id: 70,
        name: {
            en: 'Bottoms',
            ru: 'Нижний Износ',
            tr: 'Altlar',
        },
        slug: {
            en: 'bottoms',
            ru: 'niznii-iznos',
            tr: 'altlar',
        },
        productCount: '',
    },
    {
        id: 69,
        name: {
            en: 'Tops',
            ru: 'Верхняя Одежда',
            tr: 'Üstler',
        },
        child: [],
        slug: {
            en: 'tops',
            ru: 'verxnyaya-odezda',
            tr: 'ustler',
        },
        productCount: '',
    },
    {
        id: 98,
        name: {
            en: 'Sale',
            ru: 'Sale',
            tr: 'İndirim',
        },
        slug: {
            en: 'sale',
            ru: 'sale',
            tr: 'indirim',
        },
        productCount: '',
    },
    {
        id: 416,
        name: {
            en: 'Defective',
            ru: 'Defective',
            tr: 'Defolu',
        },
        slug: {
            en: 'defective',
            ru: 'defective',
            tr: 'defolu',
        },
        productCount: '',
    },
];

export default staticMenuItems;
